<template>
  <tab-simple v-model:value="currentTab" :items="Object.values(tabs)" />

  <tab-tabs v-if="!hideTabTabs" :defaultTab="currentTab" :items="Object.values(tabs)" @changeTab="changeTab" />
</template>

<script setup lang="ts">
  import { PayType } from '@skeleton/consts/method';

  withDefaults(
    defineProps<{
      hideTabTabs?: boolean;
    }>(),
    {
      hideTabTabs: false,
    }
  );

  const currentTab = defineModel('modelValue', {
    type: String,
    default: 'deposit',
  });

  const { getContent } = useProjectMethods();
  const { popupsData, defaultLocalePopupsData } = storeToRefs(useGlobalStore());

  const tabs = computed(() => {
    return {
      games: {
        id: PayType.DEPOSIT,
        title: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.deposit.title'),
      },
      sports: {
        id: PayType.WITHDRAW,
        title: getContent(popupsData.value, defaultLocalePopupsData.value, 'wallet.withdraw.title'),
      },
    };
  });

  const changeTab = (id: string) => {
    currentTab.value = id;
  };
</script>
